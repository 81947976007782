import { LogManager } from 'aurelia-framework';
import { dataUriToBlob } from '../../services/api/hooks/files/dataUriToBlob';

const log = LogManager.getLogger('dfp:cache');

export class Cache {

    cacheName: string;
    static cacheEnabled: boolean;

    constructor(name: string){
        this.cacheName = name || 'dfp';
    }

    static enable(){
        Cache.cacheEnabled = true;
    }

    static disable(){
        Cache.cacheEnabled = false;
    }

    async fetchWithCache({id, fileService}, force?){
        const response = await this.get(id);
        if(response && !force){
            return response.blob()
                .then((blob) => URL.createObjectURL(blob));
        }

        // try to fetch the file otherwise
        return fileService.get(id, { query: { $url: true } })
            .then(((result) => {
                if (result.url) {
                    return fetch(result.url)
                        .then((res) => {
                            return this.put(id, res);
                        })
                        .then((res) => {
                            return res.blob();
                        })
                        .then((blob) => URL.createObjectURL(blob));
                }

                const blob = dataUriToBlob(result.uri);
                this.put(id, new Response(blob, {
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                }));
                const url = URL.createObjectURL(blob);
                log.debug(`Creating object url: ${url}`);
                return url;

            }))
            .catch((e) => log.warn('[cache] Error fetching file', e));

    }

    async get(id): Promise<Response>{

        if(!Cache.cacheEnabled){
            log.info('disabled');
            return;
        }

        return caches.open(this.cacheName).then((cache) => {
            return cache.match(id).then((response) => {
                if(response){
                    log.info(`${id} found in local cache`);
                    return response;
                }
                log.info(`No response for ${id} found in cache`);

            });
        });
    }

    async put(id, res): Promise<Response>{
        if(!Cache.cacheEnabled){
            log.info('disabled');
            return res;
        }

        return caches.open(this.cacheName).then((cache) => {
            log.info(`putting ${id} in cache`);
            cache.put(id, res.clone());
            return res;
        }).catch((e) => log.warn('error', e));
    }
}
